import dynamic from 'next/dynamic';


export default {
    BasePage: dynamic(() => import('./BasePage')),
    HomePage: dynamic(() => import('./HomePage')),
    TextPage: dynamic(() => import('./TextPage')),
    EmployeePage: dynamic(() => import('./EmployeePage')),
    NotFoundPage: dynamic(() => import('./NotFoundPage')),
    PasswordProtectedPage: dynamic(() => import('./PasswordProtectedPage')),
    PureHtmlPage: dynamic(() => import('./PureHtmlPage')),
};
